import React, { useEffect, useRef, useState } from "react";

import stop from "../../img/versionthree/stop_button.png";
import loadingModel from "../../img/knitingale_typing_graphic.png";
import play from "../../img/versionthree/listen_button.png";
import copy from "../../img/chat/copy.svg";
import share_line from "../../img/chat/share_line.svg";
import dot_menu from "../../img/chat/dot_menu.png";
import PlayLoading from "../../img/chat/playloading.js";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import close_icon from "../../img/share/close_icon.svg";
import hand_wave_emoji from "../../img/hand_wave_emoji.png";
import LottieAnimation from '../LottieAnimation/LottieAnimation.js';
import lodinggif from "../../img/chat/knightingale_audio_loading.gif";



import "../Chat/Chat.css";

const Chat = ({
  userHistory,
  currentlyPlayingIndex,
  isVoiceSynthesisInProgress,
  handleStopButtonClick,
  playAudio,
  showLoadingIndicator,
  handlechtst1ButtonClick,
  setInput,
  responseTexts,
  suggessionTitle,
  selectedOption,
  boxSuggession,
}) => {
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);
  const { setIsButtonDisabled, setTextCount, minMaxHeight, triggerSync, showCookieMessage, setShowShareWindow, imagePreviews, input, isStyled, setAudioVideoChange } = useShareWindow();
  const [imageZoom, setImageZoom] = useState(false);
  const [imageUrlZoom, setImageUrlZoom] = useState();
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const buttonRefs = useRef([]);
  const menuRef = useRef(null);
  const [containerHeightStyle, setContainerHeightStyle] = useState({});
  const [preDotmenu, setPreDotmenu] = useState(false);
  const playButtonRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);





  const handleClickOutside = (event) => {
    if (playButtonRef.current && !playButtonRef.current.contains(event.target)) {
      setPreDotmenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  for (let i = 0; i < imagePreviews.length; i++) {
  }


  useEffect(() => {

    const isIOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    const isAndroid = () => {
      return /Android/.test(navigator.userAgent);
    };

    const calculateHeight = () => {
      let height = window.innerHeight - minMaxHeight;

      if (isIOS() || isAndroid()) {
        // Adjustments for mobile devices (iOS and Android)
        height = document.documentElement.clientHeight - minMaxHeight;
      }

      if (window.innerWidth < 767) {
        if (isIOS()) {
          return input ? height - 80 : height - 70;
        } else if (isAndroid()) {
          return input ? height - 120 : height - 110;
        }
      } else {
        return input ? height - 160 : height - 140;
      }
    };

    const newHeight = `${calculateHeight()}px`;
    const newHeightStyle = {
      minHeight: newHeight,
      maxHeight: newHeight,
    };

    setContainerHeightStyle(newHeightStyle);
  }, [input]);






  const handleTextChange = () => {
    const answerElement = document.getElementById("answer");

    if (answerElement) {
      const text = answerElement.innerText;
      const count = text.length;
      setTextCount(count);
      setIsButtonDisabled(count === 0);
    }
  };

  useEffect(() => {
    handleTextChange();

    document.addEventListener("DOMSubtreeModified", handleTextChange);

    return () => {
      document.removeEventListener("DOMSubtreeModified", handleTextChange);
    };
  }, []);

  // Use state to store an array of refs
  const [textRefs, setTextRefs] = useState([]);

  // This effect will initialize the refs when the component mounts or userHistory changes
  useEffect(() => {
    setTextRefs((prevTextRefs) =>
      userHistory.map((_, index) => prevTextRefs[index] || React.createRef())
    );
  }, [userHistory]);

  // This effect will log the refs for demonstration purposes
  useEffect(() => {
  }, [textRefs]);


  const handleCopyClick = (textRef) => () => {
    if (textRef && textRef.current) {
      const textToCopy = textRef.current.innerText;

      // Create a textarea element, set its value, and append it to the document
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select and copy the text
      textarea.select();
      document.execCommand("copy");

      // Remove the textarea from the document
      document.body.removeChild(textarea);

      // Update the showOriginal state

      // You can add any additional logic or feedback here

      // Set showOriginal to true after 2000 milliseconds
      setTimeout(() => {
      }, 2000);
    }
  };


  useEffect(() => {
    scrollToBottom();
  }, [userHistory, showLoadingIndicator]);

  const scrollToBottom = () => {
    const parentElement = document.querySelector(".q-and-a-container");
    const outputContainer = outputContainerRef.current;
    const outputInner = outputinnerContainerRef.current;

    if (outputContainer) {
      const lastChild = outputContainer.lastChild;
      const childElements = parentElement.querySelectorAll(".main-q-a-box");

      if (lastChild && childElements.length > 0) {
        const containerHeight = outputContainer.clientHeight;
        const lastChildHeight = lastChild.clientHeight;
        const scrollHeight = outputContainer.scrollHeight;
        const innerHeight = outputInner.clientHeight;
        const lastChilds = childElements[childElements.length - 1];
        const lastChildHei = lastChilds.getBoundingClientRect().height;
        if (lastChildHei > containerHeight - 45) {
          outputContainer.scrollTop =
            scrollHeight -
            (containerHeight + 80) -
            (lastChildHei - (containerHeight - 50));
        } else {
          if (scrollHeight - containerHeight <= lastChildHeight) {
            outputContainer.scrollTop = scrollHeight - (containerHeight - 50);
          } else {
            lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  };



  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHeight = () => {
    if (screenWidth <= 767) {
      return `${screenHeight * 0.5}px`;
    } else {
      return `${screenHeight * 0.42}px`;
    }
  };

  const suggessionBorderColor = {
    borderColor: boxSuggession,
  };





  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRefs.current.every(buttonRef => !buttonRef.contains(event.target))
      ) {
        setOpenMenuIndex(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRefs]);

  const handleButtonClick = index => {
    setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const addDotMenuRef = index => element => {
    buttonRefs.current[index] = element;
  };




  const [showGreeting, setShowGreeting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGreeting(true);
    }, 1300); // 1.5-second delay

    return () => clearTimeout(timer);
  }, []);












  return (
    <>
      <div className={`q-and-a-container-wrapper    ${!isStyled ? 'start-q-and-a-container' : ''}`}>
        <div
          style={containerHeightStyle}
          className={`output-area   scrollbar ${!isStyled ? 'start-output-area' : ''}`}
          id="scrollbar2"
          ref={outputContainerRef}
        >
          <pre className="q-and-a-container" ref={outputinnerContainerRef}>

            {selectedOption !== "option1" && (
              <div className="main-q-a-box">
                <div
                  className="chat-suggesst-wrapper"
                  style={{ height: getHeight() }}
                >
                  <div className="chat-suggesst-title">
                    <div className="chat-suggesst-title-inner  ">
                      <div className="chat-suggesst-title-text">
                        {suggessionTitle}
                      </div>
                    </div>
                  </div>
                  <div className={`chat-starters  ${!isStyled ? 'start-suggession-starter' : ''}`}>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[0]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[0]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[1]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[1]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[2]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[2]}
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="suggetion-wrapper">
                      <div className="suggetion-container">
                        {" "}
                        <button
                          onClick={() => {
                            handlechtst1ButtonClick();
                            setInput(responseTexts[3]);
                          }}
                          style={suggessionBorderColor}
                        >
                          <div className="suggetion-icon">
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.000000px"
                              height="20.000000px"
                              viewBox="0 0 32.000000 32.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <g
                                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                                stroke="none"
                              >
                                <path
                                  className="colored-path"
                                  fill={boxSuggession}
                                  d="M10 246 l0 -54 50 -12 c28 -7 52 -15 55 -19 2 -5 -20 -13 -50 -20 l-55 -12 0 -55 c0 -41 4 -54 15 -54 24 0 285 128 285 140 0 11 -262 140 -286 140 -10 0 -14 -15 -14 -54z"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="response-wrapper">
                            {responseTexts[3]}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div style={{ marginRight: '20px', marginTop: !showCookieMessage ? '105px' : '180px' }} className={` bot-msg-bubble delay_greeting ${showGreeting ? 'show_greeting' : ''}`}>
              <div className="bot-msg-bubble-container " style={{ marginBottom: '10px' }}>

                <div className="bot-msg-text" style={{ justifyContent: 'left', paddingTop: '0px' }}>
                  <p
                    className="answer-for-questions"
                    id="answerpre"
                    onChange={handleTextChange}
                  ><span className="pre_define_message" style={{ marginBottom: '5px', lineHeight: '26px', fontSize: '22px', fontWeight: '500', display: 'flex', justifyContent: 'row', alignItems: 'center' }}>Hello! I’m Knightingale, <img src={hand_wave_emoji} alt="" style={{
                    width: '20px', display: 'flex'
                    , alignItems: 'center'
                  }} /> <br /></span><span className="pre_define_message_title" style={{ lineHeight: '26px', fontSize: '20px' }}>
                      Your Caring Health Companion Powered by AI</span> <br />
                    <span className='mobile_welcome'>Let me guide you towards better health and well-being. Think of me as a wise, loving friend by your side, offering wellness guidance and stepwise plans!</span>
                  </p>
                </div>

              </div>

            </div>

            {userHistory.map((entry, index) => (

              <div className="main-q-a-box" key={index}>
                <div className="client-msg-bubble">
                  <div className="client-questions">
                    <div className="client_questin_text" style={{ width: '100%' }}>
                      <h2>
                        {entry.input}
                      </h2>
                    </div>
                    {imagePreviews[index] != null && <div style={{ maxWidth: '70ch', width: '100%', height: '200px', overflow: 'hidden' }}
                      onClick={() => { setImageZoom(!imageZoom); setImageUrlZoom(imagePreviews[index]) }}
                    >
                      <img style={{ width: '100%', height: '100%', objectFit: 'cover', margin: '10px 0px', borderRadius: '15px' }} src={imagePreviews[index]} alt="" />
                    </div>
                    }
                  </div>
                </div>
                <div className="preview_wrapper">
                </div>

                <div className="bot-msg-bubble" >
                  <div className="bot-msg-bubble-container" >
                    <div className="play-button-container">
                      {isVoiceSynthesisInProgress && (
                        <div className="play_loading_wrapper">
                          <div className="play_loading_container">
                            {/* <PlayLoading /> */}
                            {/* <LottieAnimation /> */}
                            <img src={lodinggif} alt="lodinggif" className="lodinggif" />
                          </div>
                        </div>
                      )}
                      {!isVoiceSynthesisInProgress && // Check if audio synthesis is not in progress
                        (currentlyPlayingIndex === index ? (
                          <button
                            type="button"
                            onClick={() => { handleStopButtonClick(); setAudioVideoChange(false); }}
                            className={`stop-button ${entry.response ? "" : "hidden"
                              }`}
                          >
                            <img
                              src={stop}
                              height={20}
                              width={20}
                              alt="Knightingale said"
                              className="stop-icon"
                            />
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              playAudio(entry.response, index);
                              triggerSync();
                            }

                            }
                            className={`play-audio-button ${entry.response ? "" : "hidden"
                              }`}
                          >
                            {currentlyPlayingIndex !== index ? (
                              <img
                                src={play}
                                height={30}
                                width={30}
                                alt="Knightingale says"
                                className="play-icon"
                              />
                            ) : null}
                          </button>
                        ))}

                      <div key={index} className="play-button-menu" ref={addDotMenuRef(index)} onClick={() => { handleButtonClick(index) }} style={{
                        backgroundColor:
                          openMenuIndex === index ? "#0000004a" : "",
                      }} >
                        <img src={dot_menu} alt="dot_menu" />
                        {openMenuIndex === index && (<div>
                          <div ref={menuRef} className="dotmenu-wrapper">
                            <div className="dotmenu-container">
                              <div className="dotmenu-menu" onClick={() => {
                                handleCopyClick(textRefs[index])();
                              }}>
                                <img src={copy} alt="dot_menu" />
                                Copy Text
                              </div>
                              <div className="dotmenu-menu" onClick={() => setShowShareWindow(true)}>
                                <img src={share_line} alt="dot_menu" />
                                Share Thread
                              </div>
                            </div>
                          </div>
                        </div>)
                        }
                      </div>
                    </div>
                    <div className="bot-msg-text" ref={textRefs[index]}>
                      <p
                        dangerouslySetInnerHTML={{ __html: entry.response }}
                        className="answer-for-questions"
                        id="answer"
                        onChange={handleTextChange}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {showLoadingIndicator && (
              <div className="main-q-a-box loading-indicator">
                <div className="client-msg-bubble left-client-msg-bubble">
                  <div className="client-questions bacground-client-msg-bubble">
                    <div className="loading-area">
                      <div className="loader">
                        <div className="loading-model">
                          <img src={loadingModel} alt="Knightingale Typing" />
                        </div>
                        <div
                          className={`loading-text loading-text-animate loadedAnimate`}
                        >
                          <span className="
                          element-to-move 
                          typewriter-text
                          ">
                            Knightingale is typing
                          </span>
                        </div>
                        <div className="loading-sphere"></div>
                        <div className="loading-sphere"></div>
                        <div className="loading-sphere"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </pre>
        </div>
        {imageZoom &&
          <div className="image_zoom_cover">
            <div className="image_zoom_image" onClick={() => { setImageZoom(false) }}>
              <img src={close_icon} alt="close_icon" className="image_zoom_img" />
            </div>
            <div className="image_zoom_wrapper">
              <img style={{}} src={imageUrlZoom} alt="" />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Chat;
