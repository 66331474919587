import React from 'react'
import "./PopupMic.css";
import close_icon from "../../img/share/close_icon.svg";
import mic_graphic_popup from "../../img/chat/mic_graphic_popup.png";
import { useShareWindow } from "../../context/ShareWindowContext.js";

const PopupMic = () => {
  const { showPopup, setShowPopup, isFirstClick, setIsFirstClick } = useShareWindow();

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className='popup-wrapper'>
      <div className="popup">
        <div className="popup-content">
          {/* <span className="close close-button" ><img src={close_icon} alt="Twitter" className="" />
          </span> */}
          <div className='popup-mic-img'>
            <img src={mic_graphic_popup} alt='popup mic mic' />
          </div>
          <div className='popup-mic-title' >
            <h4>Enable microphone to chat with Knightingale.</h4>
          </div>
          <div className='popup-mic-text'>
            <p>You need to allow Knightingale to access your microphone, so she can understand what you're saying.</p>
          </div>
          <div className='popup-mic-button'>
            <button onClick={closePopup}>Got it</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default PopupMic