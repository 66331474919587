import React, { useCallback, useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import { useParams } from "react-router-dom";
import userlogo from "../../img/Icons/login.png";
import knightingale_profile_image from "../../img/knightingale_profile_image.jpg";
import mainlogo from "../../img/logo/knightingale_logo.png";
import LoginPage from "../Login/Login";
import "./MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";
import chat_icons from "../../img/chat/chat_icon.svg";
import home_icons from "../../img/chat/home.svg";
import share_icons from "../../img/chat/share_icon.svg";
import telegram_icon from "../../img/chat/telegram_icon.svg";
import whatsapp_icon from "../../img/chat/whatsapp_icon.svg";
import burger_menu from "../../img/versionthree/burger_menu.svg";
import burger_menu_mobile from "../../img/versionthree/burger_menu_mobile.svg";
import back_icon from "../../img/versionthree/back_icon.svg";
import knightingale_mobile_idle from "../../videos/chat/knightingale_mobile_idle.mp4";
import knightingale_mobile_lipsync from "../../videos/chat/knightingale_mobile_lipsync.mp4";
import knightingale_idle from "../../videos/chat/knightingale_idle.mp4";
import knightingale_lipsync from "../../videos/chat/knightingale_lipsync.mp4";
import knightingale_thumbnail from "../../img/knightingale_thumbnail.jpg";
import knightingale_mobile_thumbnail from "../../img/chat/knightingale_mobile_thumbnail.jpg";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import ShareWindow from "../Sharewindow/ShareWindow.js";
import axios from "axios";
import PopupMic from "../PopupMic/PopupMic.js";
import Cookies from 'js-cookie';
import VideoToggle from "../VideoToggle/VideoToggle.js";
import { Helmet } from 'react-helmet';
import VideoCanvas from "../VideoCanvas/VideoCanvas.js";
import VideoTime from "../VideoToggle/VideoTime.js";


function Test(props) {
  const buttonRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [backgroundMp, setBackgroundMp] = useState(knightingale_idle);
  const [centerHome, setCenterHome] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);
  const [nickname, setNickname] = useState("");
  const { session: urlSession } = useParams();
  const { showCookieMessage, setShowCookieMessage,
    showPopup,
    showShareWindow,
    setShowShareWindow,
    chatSession,
    setChatSession,
    setBackgroundText
    , setBackgroundImg, minMaxHeight, setDeskNav, backgroundreturnColor, setBackgroundreturnColor, deskNav, isStyled, setIsStyled, setChatStart, audioVideoChange
  } = useShareWindow();



  useEffect(() => {
    // Retrieve value from localStorage when component mounts
    const chatType = localStorage.getItem('chatType');


    if (chatType) {
      // alert(`Retrieved value from localStorage: ${chatType}`);
      setIsStyled(!isStyled);
      setChatStart(true);

      localStorage.removeItem('chatType');
    }

    return (chatType)


  }, []);

  const uniqueLink = `https://ask.knightingale.ai/share/${chatSession}`;
  // const uniqueLink = `https://.knightingale.ai/knask-video/share/${chatSession}`;

  const [conversationHistory, setConversationHistory] = useState([]);




  // useEffect(() => {
  //   // Fetch user nickname from the backend
  //   fetch(SET_NICKNAME)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setNickname(data.nickname);
  //     })
  //     .catch((error) => console.error("Error fetching user nickname:", error));
  // }, []);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay error:", error);
      });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  const divStyle = {
    backgroundColor: backgroundColor,
  };

  const backgroundOpacity = .5;

  const returnStyle = {
    backgroundColor: `rgba(${parseInt(
      backgroundreturnColor.slice(1, 3),
      16
    )}, ${parseInt(backgroundreturnColor.slice(3, 5), 16)}, ${parseInt(
      backgroundreturnColor.slice(5, 7),
      16
    )}, ${backgroundOpacity})`,
    borderColor: backgroundreturnColor,
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);


  useEffect(() => {
    const updateScreenHeight = () => {
      const newHeight = Math.max(648, Math.min(1500, window.innerHeight));
    };

    // Update screen height when the window is resized
    window.addEventListener("resize", updateScreenHeight);

    // Initial update
    updateScreenHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  let backClicked = false;

  if (!centerHome) {
    window.addEventListener("popstate", function (event) {
      if (!backClicked) {
        backClicked = true;
      } else {
        window.addEventListener("hashchange", function () {
          window.location.reload();
        });
      }
    });
  }

  useEffect(() => {
    if (centerHome === false) {
      const handleClick = () => {
      };

      const autoclickElement = document.getElementById("autoclick");

      if (autoclickElement) {
        autoclickElement.addEventListener("click", handleClick);
        autoclickElement.click();
        return () => {
          autoclickElement.removeEventListener("click", handleClick);
        };
      }
    }
  }, [centerHome]);


  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://dashboard.knightingale.ai/ai/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data.conversations);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://dashboard.knightingale.ai/ai/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]);


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [zstyle, setZstyle] = useState(false);
  const idleVideoRef = useRef(null);
  const lipsyncVideoRef = useRef(null);

  
  useEffect(() => {
    const idleVideo = idleVideoRef.current;
    const lipsyncVideo = lipsyncVideoRef.current;

    if (idleVideo && lipsyncVideo) {
        syncVideoTimes(idleVideo, lipsyncVideo);
        idleVideo.play();
        lipsyncVideo.play();
    }

}, [audioVideoChange]);

const syncVideoTimes = (idleVideo, lipsyncVideo) => {
    const syncTime = () => {
        // Synchronize video times
        if (Math.abs(idleVideo.currentTime - lipsyncVideo.currentTime) > 0.1) {
            idleVideo.currentTime = lipsyncVideo.currentTime;
        } else if (Math.abs(lipsyncVideo.currentTime - idleVideo.currentTime) > 0.1) {
            lipsyncVideo.currentTime = idleVideo.currentTime;
        }
    };

    // Update time every frame
    const interval = setInterval(syncTime, 100); // Sync every 100ms

    return () => {
        clearInterval(interval); // Clean up interval on component unmount or effect rerun
        idleVideo.pause();
        lipsyncVideo.pause();
    };
};


  const handleMouseEnter = (index) => {
    setShowTooltip(index);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };
  const handleMouseEnterShare = () => {
    // setZstyle(true)
    // const timeout = setTimeout(() => {
    //   setShowTooltip(true);
    // }, 2000); // 2 seconds
    // setHoverTimeout(timeout);
  };

  const handleMouseLeaveShare = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
    setZstyle(false)
  };



  const newChatClick = () => {
    // Store 'newchat' in localStorage
    localStorage.setItem('chatType', 'newchat');
    // alert("Value 'newchat' has been stored in localStorage.");
  };



  const [objectFitStyle, setObjectFitStyle] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const screenRatio = screenWidth / screenHeight;

      // Check the screen ratio and set the object-fit style accordingly
      if (screenRatio < 1.8) {
        setObjectFitStyle('cover');
      } else {
        setObjectFitStyle('cover');
      }
    };

    // Call handleResize on initial mount and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    const messageShown = Cookies.get('messageShown');
    if (!messageShown) {
      setShowCookieMessage(true);
    }
  }, []);

  const handleOkClick = () => {
    // Set the cookie when the user clicks OK
    Cookies.set('messageShown', 'true', { expires: 7 }); // The cookie will expire in 7 days
    setShowCookieMessage(false);
  };

  useEffect(() => {
    console.log("first" + loaded)
    setDeskNav(false)
  }, [!loaded]);



  const openWhatsAppChat = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://knightingale.ai/api/whatsapp/chat', {
        // Add any necessary data here
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      // Handle the response data
      // Redirect or perform other actions based on the response
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const imgRef = useRef(null);

  const refreshImage = useCallback(() => {
    if (imgRef.current) {
      const currentSrc = imgRef.current.src;
      // Append a timestamp to the URL to force the browser to reload the image
      imgRef.current.src = currentSrc.split('?')[0] + '?' + new Date().getTime();
    }
  }, []);
  



  return (
    <div
      className={`main-three-colum-layout-wrapper  ${!isStyled ? 'start-bg-color' : ''} `}
      style={divStyle}
    >
      <Helmet>
        <title>Welcome to Knightingale - Your AI Health Companion</title>
        <meta
          name="description"
          content="Let me guide you towards better health and well-being. Think of me as a wise, loving friend by your side, offering wellness guidance and stepwise plans."
        />
        <meta property="og:title" content="Welcome to Knightingale - Your AI Health Companion" />
        <meta
          property="og:description"
          content="Let me guide you towards better health and well-being. Think of me as a wise, loving friend by your side, offering wellness guidance and stepwise plans."
        />
        <meta property="og:image" content="https://ask.knightingale.ai/Knightingale_share_image.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ask.knightingale.ai/" />
      </Helmet>
      <div className="main-three-colum-layout-container">

        <div className="desk_home_bg_video_wrapper">
          <div className="desk_home_bg_video_container">
            <div className="new-background-video-container-chat videoclass">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}

                className="video_wrapper_dp"
              >

                {/* <VideoCanvas audioVideoChange={audioVideoChange}/> */}
                <video
                  ref={idleVideoRef}
                  className={`new-background-bg-video-container-chat-video desktop_background_video `}
                  style={{
                    objectFit: objectFitStyle,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: audioVideoChange ? 0 : 1, // Use opacity to show/hide
                    transition: 'opacity 0.005s ease-in-out', // Smooth 

                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  preload="auto"
                  poster={knightingale_thumbnail}
                  src={knightingale_idle}
                />

                <video
                  className={`new-background-bg-video-container-chat-video mobile_background_video `}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    opacity: audioVideoChange ? 0 : 1, // Use opacity to show/hide
                    transition: 'opacity 0.005s ease-in-out', // Smooth 
                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={knightingale_mobile_thumbnail}
                  src={knightingale_mobile_idle}
                />


                <video
                  ref={lipsyncVideoRef}
                  className="new-background-bg-video-container-chat-video desktop_background_video"
                  style={{
                    objectFit: objectFitStyle,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: audioVideoChange ? 1 : 0, // Use opacity to show/hide
                    transition: 'opacity 0.005s ease-in-out', // Smooth 

                  }
                  }
                  playsInline
                  autoPlay
                  loop
                  muted
                  preload="auto"
                  poster={knightingale_thumbnail}
                  src={knightingale_lipsync}
                />

                <video
                  className="new-background-bg-video-container-chat-video mobile_background_video"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    opacity: audioVideoChange ? 1 : 0, // Use opacity to show/hide
                    transition: 'opacity 0.005s ease-in-out', // Smooth 
                  }
                  }
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={knightingale_mobile_thumbnail}
                  src={knightingale_mobile_lipsync}
                />
              

              {/* <VideoToggle /> */}
{/* <VideoTime/> */}
              </div> 
            </div>
          </div>
        </div>
        <LoginPage isOpen={isPopupOpen} onClose={closePopup}></LoginPage>
        <div
          className={`main-three-colum-layout    ${!isStyled ? 'start-main-three-colum-layout' : ''}`}
          style={divStyle}
        >
          <video
            ref={videoRef}
            className="new-mobile-background-video"
            playsInline
            muted
            loop
          />

          <button
            ref={buttonRef}
            onClick={() => handlePlayClick()}
            style={{ display: "none" }}
          ></button>
          <div className={`left-column   ${!isStyled ? 'start-left-column' : ''}`}>
            <div className={`left-column-logo  ${!isStyled ? '' : 'left-column-logo-hide'}`}>
              <div className="nav_desktop_tbtn">
                <div className="toggle_menu_wrapper" onClick={() => { setDeskNav(!deskNav) }}>
                  <img className="toggle_menu_desk" src={burger_menu_mobile} alt="Image 1" />
                </div>
              </div>
              {/* <a href="https://ask.knightingale.ai/share/">
                <img
                  className="logo-kps menu-logo-kps "
                  src={mainlogo}
                  alt="Image 1"
                />

              </a> */}

              <div className="right_side_nav">
                <div className="right_side_nav_inner" onClick={openPopup}>
                  <img className="user-kps " src={userlogo} alt="Image 1" style={{ width: '40px' }} />
                </div>
              </div>
            </div>

            <div className={`left-column-vertical-buttons ${deskNav ? '' : 'deskNav'}`}>
              <a href="/" onClick={refreshImage}>
                <img
                  ref={imgRef}
                  className="logo-kps menu-logo-kps "
                  src={mainlogo}
                  alt="Image 1"
                />

              </a>
              <div className="vertical-button-wrapper">
                <div className="vertical-button"
                  style={{ position: 'relative' }}
                >
                  <a href="https://knightingale.ai/"
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}>
                    <img
                      className="vertical-button-img"
                      src={home_icons}
                      alt="Image 1"
                    />
                    <div className="side_nav_text">24/7<span style={{ color: 'transparent' }}>_</span>Chat
                    </div>
                  </a>
                  {showTooltip === 0 && (
                    <div className="tooltip-text">Home</div>
                  )}
                </div>
                <div className="vertical-button"
                  style={{ position: 'relative' }}


                >

                  <div className="sharebtn-wrapper start-sharebtn-wrapper"
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="sharebtn-inner">
                      <div className="sharebtn"
                        onMouseEnter={handleMouseEnterShare}
                        onMouseLeave={handleMouseLeaveShare}
                      >

                        <button
                          className="sharebtn-img tooltip"
                          onClick={() => setShowShareWindow(true)}
                        // disabled={isButtonDisabled}

                        >
                          <img
                            className="vertical-button-img share"
                            src={share_icons}
                            alt="Image 1"
                          />
                          <div className="side_nav_text">Share</div>
                        </button>
                        {showShareWindow && (
                          <ShareWindow
                            generatedLink={uniqueLink}
                            onClose={() => setShowShareWindow(false)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {showTooltip === 1 && (
                    <div className="tooltip-text">Share</div>
                  )}
                </div>
                <div className="vertical-button"
                  style={{ position: 'relative' }}

                >
                  <a href="https://wa.me/+15165708615" target="_blank"
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      className="vertical-button-img"
                      src={whatsapp_icon}
                      alt="Image 1"
                    />
                    <div className="side_nav_text">
                      WhatsApp
                    </div>
                  </a>
                  {showTooltip === 2 && (
                    <div className="tooltip-text">WhatsApp</div>
                  )}
                </div>

                {/* <div className="vertical-button"
                >
                  <a href="https://t.me/Edu_Advisor_Bot" target="_blank">
                    <img
                      className="vertical-button-img"
                      src={telegram_icon}
                      alt="Image 1"
                    />
                    <div className="side_nav_text">Telegram
                    </div>
                  </a>
                </div> */}

              </div>


            </div>
            <div className="video-wrapper">
              {/* <a href="/" onClick={refreshImage}>
                <img
                  ref={imgRef}
                  className="logo-kps menu-logo-kps "
                  src={mainlogo}
                  alt="Image 1"
                /> 

              </a>*/}
              <div className="new-background-video-container-chat videoclass">

              </div>
              {/* {showCookieMessage && <div className="cookie_bars" style={{}}>
                <p>Click to allow cookies and improve your experience!</p>
                <button onClick={handleOkClick}>Accept All</button>
              </div>
              } */}
            </div>
          </div>
          {<a href="#" id="autoclick"></a>}

          <div
            className={`center-column  ${!isStyled ? 'start-central-column' : ''}  ${showCookieMessage ? 'showCookieMessage' : 'hideCookieMessage'}`}
          >

            <div className="mobile_1v3_nav">
              <div className="mobile_1v3_nav_wrapper">
                <div className="mobile_1v3_nav_container">
                  <div className="mobile_1v3_nav_toggle">
                    <div className="toggle_menu_wrapper" onClick={() => { setMobileNav(!mobileNav) }}>
                      <img className="toggle_menu" src={burger_menu_mobile} alt="Image 1" />
                    </div>
                  </div>

                  <div className="mobile_1v3_nav_logo">
                    <a href="/" onClick={refreshImage}>
                      <img
                        ref={imgRef}
                        className="logo-kps menu-logo-kps "
                        src={mainlogo}
                        alt="Image 1"
                      />
                    </a>
                  </div>
                  <div className="mobile_1v3_nav_profile " onClick={openPopup}>
                    <img className="user-mobile " src={knightingale_profile_image} alt="Image 1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_1v3_side_nav_open start_mobile_1v3_side_nav ">
              <div className={`mobile_1v3_side_nav ${mobileNav ? "mobile_1v3_side_nav_open" : ""
                }`}>
                <div className={`mobile_1v3_side_nav_wrapper   ${mobileNav ? 'mob_bg_overlay' : ''}`}>
                  <div className="mobile_1v3_side_nav_container">
                    <div className="side_nav_menu_container">
                      <div className="side_nav_menu_item_wrapper">
                        <div className="side_nav_menu_item_container">
                          <div className=" side_nav_menu_item_back">
                            <a href="#" onClick={() => { setMobileNav(!mobileNav) }} className="">
                              <img
                                className="vertical-button-img"
                                src={back_icon}
                                alt="Image 1"
                              />
                            </a>
                          </div>
                          <div className="side_nav_menu_item_wrapper">

                            <div className="side_nav_menu_item">
                              <a href="https://knightingale.ai/">
                                <img
                                  className="vertical-button-img"
                                  src={home_icons}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  Home
                                </div>
                              </a>
                            </div>

                            {/* <div className="side_nav_menu_item">
                              <a href="https://t.me/Edu_Advisor_Bot" target="_blank">
                                <img
                                  className="vertical-button-img"
                                  src={telegram_icon}
                                  alt="Image 1"
                                />
                                <div className="side_nav_menu_item_text">
                                  Telegram
                                </div>
                              </a>
                            </div> */}
                            <div className="side_nav_menu_item" onClick={() => setShowShareWindow(true)}>
                              <div className="sharebtn-wrapper start-sharebtn-wrapper">
                                <div className="sharebtn-inner">
                                  <div className="sharebtn"
                                    onMouseEnter={handleMouseEnterShare}
                                    onMouseLeave={handleMouseLeaveShare}
                                  >

                                    <button
                                      className="sharebtn-img tooltip"
                                      onClick={() => setShowShareWindow(true)}
                                    // disabled={isButtonDisabled}

                                    >
                                      <img
                                        className="vertical-button-img share"
                                        src={share_icons}
                                        alt="Image 1"
                                      />
                                      {showTooltip && <div class="tooltip">
                                        <span class="tooltiptext">Share</span>
                                      </div>}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="side_nav_menu_item_text">
                                Share
                              </div>
                            </div>
                            <div className="side_nav_menu_item">
                              <a href="https://wa.me/+15165708615" target="_blank">
                                <img
                                  className="vertical-button-img"
                                  src={whatsapp_icon}
                                  alt="Image 1"
                                />
                                <div className="side_nav_menu_item_text">
                                  WhatsApp
                                </div>
                              </a>


                            </div>
                            {/* <div className="side_nav_menu_item" onClick={newChatClick}>
                              <a href="https://ask.knightingale.ai/share/"  >
                                <img
                                  className="vertical-button-img"
                                  src={chat_icon}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  New Chat
                                </div></a>
                            </div> */}
                          </div>
                        </div>
                        <div className="side_nav_menu_item_bottom">
                          <div className="side_nav_menu_item_bottom_text">
                            {/* © 2024 Knightingale, Inc. */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>




            {/* <div className={`central-greeting-wrapper-mobile ${!isStyled ? 'start-central-greeting-wrapper' : ''}`}>
           
            </div> */}
            <div className={` start-central-video-display  ${!isStyled ? 'start-cental-video-wrapper' : ''}`}>
              <div className="video-wrapper videoclass" id="yourVideoDivId">
                <div className="new-background-video-container videoclass">
                  <video
                    className="start-video"
                    id="start_video"
                    playsInline
                    autoPlay
                    loop
                    muted
                  />

                </div>
              </div>
            </div>


            <div style={{}} className="greeting_form_wrapper">
              <div className="central-greeting-wrapper-desktop">
                <div
                  className={`central-greeting-wrapper  app  ${!isStyled ? 'start-central-greeting-wrapper' : ''}  ${loaded ? "loaded" : ""} `} style={{ top: (minMaxHeight * -1) < 2 ? `${-70 + (minMaxHeight * -1)}px` : `${-80}px` }}

                >
                </div>
              </div>
              <Form
                setBackgroundColor={setBackgroundColor}
                setBackgroundImg={setBackgroundImg}
                setBackgroundText={setBackgroundText}
                setBackgroundMp={setBackgroundMp}
                setBackgroundreturnColor={setBackgroundreturnColor}
                backgroundMp={backgroundMp}
                setCenterHome={setCenterHome}
              />
            </div>
          </div>

          <div className="right-column ">
            <Navbar setCenterHome={setCenterHome} />
          </div>
        </div>
        {showShareWindow && (
          <ShareWindow
            generatedLink={uniqueLink}
            onClose={() => setShowShareWindow(false)}
          />
        )}
        {
          showPopup && <PopupMic />
        }
      </div>
      {/* {showCookieMessage &&   <div className="cookie_bars cookie_bars_mobile" style={{ }}>
      <p>Click to allow cookies and improve your experience!</p>
      <button onClick={handleOkClick}>Accept All</button>
      </div>
      } */}
    </div>
  );
}

export default Test;
