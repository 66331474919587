import React, { useState, useEffect } from 'react';
import axios from 'axios';
import backImage from '../../img/arrow.png';
import { Link } from 'react-router-dom';


// import './Dashboard.css';

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [userInput, setUserInput] = useState('');
  const [completion, setCompletion] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [updateMessage, setUpdateMessage] = useState('');
  const [fixedPrompt, setFixedPrompt] = useState('');
  const [summaryPrompt, setSummaryPrompt] = useState('');



  const handleFormSubmit = async (event) => {
    event.preventDefault();


    try {
      
      const response = await axios.post('https://knightingale.ai/api/ai', {
        selectedOption,
        conversations: [
          {
            role: 'system',
            content: fixedPrompt, // Pass the fixed prompt message
          },
          {
            role: 'user',
            content: userInput,
          },
        ],
      });

      const { completion, audioUrl } = response.data;
      setCompletion(completion);
      setAudioUrl(audioUrl);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateSystemMessage = async () => {
    const message = userInput;

    try {
      await axios.put(`https://knightingale.ai/api/system-message/${selectedOption}`, { message });
      setUpdateMessage('Fixed Prompt updated successfully');
    } catch (error) {
      console.error('Failed to update system message:', error);
      setUpdateMessage('Failed to update Fixed Prompt');
    }
  };

  useEffect(() => {
    const fetchFixedPrompt = async () => {
      try {
        //for server use : https://knightingale.ai/api/get-message/${selectedOption}
        //for local running use : https://knightingale.ai/api/get-message/${selectedOption}
        const response = await axios.get(`https://knightingale.ai/api/get-message/${selectedOption}`);
        const { message } = response.data;
        setFixedPrompt(message);
      } catch (error) {
        console.error('Failed to fetch fixed prompt:', error);
      }
    };

    fetchFixedPrompt();
  }, [selectedOption]);

  useEffect(() => {
    if (updateMessage) {
      const timer = setTimeout(() => {
        setUpdateMessage('');
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [updateMessage]);

   // Fetch summary prompt
   useEffect(() => {
    const fetchSummaryPrompt = async () => {
      try {
        const response = await axios.get('https://knightingale.ai/prompt_retrive');
        const { promptText } = response.data;
        setSummaryPrompt(promptText);
      } catch (error) {
        console.error('Failed to fetch summary prompt:', error);
      }
    };

    fetchSummaryPrompt();
  }, []);

  const handleUpdatePrompt = async () => {
    try {
      await axios.put('https://knightingale.ai/prompt_update', { promptText: userInput });
      setUpdateMessage('Prompt updated successfully.');
    } catch (error) {
      console.error('Failed to update prompt:', error);
      setUpdateMessage('Failed to update prompt.');
    }
  };

  return (
    <div className='full-color-bg-kps'>
      <div className='main-container-login dashboard-main-container'>
        <div className='main-container-inner-login'>
          <div className="login-container dashboard-container">
            <div className="login-card dashboard-card">
              <div className="">
                <div className='back-button-kps-style'>
                  <a className='back-btn' href="https://www.example.com">
                    <img src={backImage} alt="Home" />
                  </a>
                </div>
                <h2>Dashboard</h2>
                <form className="form-container" onSubmit={handleFormSubmit}>
                  <div className='kps-form-group'>
                    <label htmlFor="option" className="label">
                      Select an option:
                    </label>
                    <select
                      id="option"
                      className="select kps-select-option"
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      <option value="option1">Knightingale</option>
                      <option value="option2">Symptom Guide</option>
                      <option value="option3">Report Analyzer</option>
                      <option value="option4">Calorie Counter</option>
                      <option value="option6">Wellness Coach</option>
                      <option value="option7">Sleep Helper</option>
                      <option value="option8">Workout Planner</option>
                      <option value="option9">Women Relations</option>
                      <option value="option10">Guru Lexi</option>
                      <option value="option11">Care Reminder</option>
                    </select>
                  </div>
                  <div className='kps-form-group'>
                    <label htmlFor="message" className="label">
                      Enter a message:
                    </label>
                    <textarea type="text"
                      id="message"
                      className="input kps-login-input"
                      rows={10}
                      cols={40}
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)} />
                  </div>
                  <div className='test-submission-section'>
                    {completion && (
                      <div className="completion-container">
                        <div className="completion">Completion:</div>
                        <div className="completion-text">{completion}</div>
                      </div>
                    )}

                    {audioUrl && (
                      <div className="audio-container">
                        <audio controls className="audio">
                          <source src={audioUrl} type="audio/wav" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}
                    <div className='full-with-btn-container'>
                      <button type="submit" className="submit-button kps-submit-button">
                        Submit For Test
                      </button>
                      <button type="button" className="update-button kps-submit-button" onClick={updateSystemMessage}>
                        Update Fixed Prompt
                      </button>
                    </div>
                    <br></br>
                    <div className='full-with-btn-container'>
                      <Link to="/Onangwatgo-Dálach">Go to Conversation History</Link>
                    </div>
                  </div>

                  <div className='fixed-prompt-section'>
                    <div className="fixed-prompt">Fixed Prompt: {fixedPrompt}</div>
                    {updateMessage && <div className="update-message">{updateMessage}</div>}
                  </div>
                  <br></br><br></br>

                  <div>
                    <label htmlFor="summaryPrompt">Current Summary Prompt:</label>
                    <div id="summaryPrompt">{summaryPrompt}</div>
                      <br></br><br></br>
                    <label htmlFor="newPrompt">Enter New Summary Prompt:</label>
                    <textarea
                      id="newPrompt"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      rows={4}
                      cols={50}
                    />

                    <button onClick={handleUpdatePrompt}>Update Summary Prompt</button>

                    {updateMessage && <div>{updateMessage}</div>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
